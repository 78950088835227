import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Avatar from "@mui/material/Avatar";
import Filters from "./components/Filters";
import { Grid2, Typography } from "@mui/material";
import { containsAll, containsAny } from "./util";
import Lawyer from "./components/Lawyer";

const _ = require("lodash");

const pluginDirName = "itech-find-a-tech-lawyer";
const cacheEndpoint = `/wp-content/plugins/${pluginDirName}/data/members.json`;

const defaultFilterState = {
  firstName: "",
  lastName: "",
  companyName: "",
  email: "",
  city: "",
  countries: [],
  areasOfLaw: [],
  areasOfLawToggle: "any",
  areasOfInterest: [],
  areasOfInterestToggle: "any",
};

function App() {
  const [isTableView, setIsTableView] = useState(true);
  const [record, setRecord] = useState(null);
  const [cachedData, setCachedData] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectOptions, setSelectOptions] = useState(null);
  const [filters, setFilters] = useState({
    ...defaultFilterState,
  });

  useEffect(() => {
    fetchData();

    // look for URL param, this determines if we show table or single lawyer view
    const params = new URLSearchParams(window.location.search);
    if (params.get("id")) {
      setIsTableView(false);
      setRecord(params.get("id"));
    }
  }, []);

  // after data is fetched we can gather select options from the available options
  useEffect(() => {
    if (data?.members) {
      gatherSelectOptions();
    }
  }, [data]);

  // whenever the filters are changed we will run filterData
  useEffect(() => {
    filterData();
  }, [filters]);

  async function fetchData() {
    const response = await fetch(cacheEndpoint);

    if (response.ok) {
      const data = await response.json();
      setData(data);
      setCachedData(data);
    } else {
      setError({
        level: "Error",
        details:
          "There was an issue fetching data, try reloading the page. If the problem persists, contact a website administrator.",
      });
    }

    setLoading(false);
  }

  // gather all options from member data and return a single array of sorted unique values
  function gatherSelectOptions() {
    function gather(property, arr = cachedData?.members) {
      if (!arr) return;

      return _.sortedUniq(
        arr
          .map((m) => {
            const val = m[property];
            if (!val || val?.length === 0) return false;
            return val;
          })
          .flat()
          .toSorted()
          .filter((o) => o) // remove all 'false' entries
      );
    }

    const areasOfLaw = gather("AreasofLaw");
    const areasOfInterest = gather("areas_of_interest");

    const addresses = gather("addresses");
    const countries = gather("country", addresses);

    setSelectOptions({
      areasOfLaw,
      areasOfInterest,
      countries,
    });
  }

  function handleFilterChange(key, val) {
    setFilters({ ...filters, [key]: val });
  }

  function handleAreaToggleChange(key, val) {
    switch (key) {
      case "law":
        setFilters({ ...filters, areasOfLawToggle: val });
        break;
      case "interest":
        setFilters({ ...filters, areasOfInterestToggle: val });
        break;
      default:
        break;
    }
  }

  function handleFilterSubmit() {
    filterData();
  }

  function filterData() {
    if (!cachedData) return;

    const filteredMemberData = cachedData.members.filter((item) => {
      // firstName
      if (
        filters.firstName &&
        !item.firstName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(filters.firstName.toLowerCase())
      )
        return false;

      // lastName
      if (
        filters.lastName &&
        !item.lastName
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .includes(filters.lastName.toLowerCase())
      )
        return false;

      // companyName
      if (
        filters.companyName &&
        !item.primaryOrganization
          .map((o) => o.name)
          .join("")
          .toLowerCase()
          .includes(filters.companyName)
      )
        return false;

      // emails
      if (filters.email) {
        if (item.emails.length === 0) return false;

        if (!item.emails.join("").toLowerCase().includes(filters.email))
          return false;
      }

      // City
      if (
        filters.city &&
        !item.addresses
          .map((a) => a.city)
          .join("")
          .toLowerCase()
          .includes(filters.city)
      )
        return false;

      // Countries
      if (filters.countries.length > 0) {
        // break early if the current item does not have and addresses
        if (item.addresses.length === 0) return false;

        const addresses = item.addresses.map((a) => a.country);
        console.log(addresses);
        if (
          !containsAny(addresses, filters.countries) // check if the items address array DOES NOT contain any matches from the filter
        )
          return false;
      }

      // Areas of Law
      if (filters.areasOfLaw.length > 0) {
        if (filters.areasOfLawToggle === "all") {
          // all
          if (!containsAll(item.AreasofLaw, filters.areasOfLaw)) return false;
        } else {
          // any
          if (!containsAny(item.AreasofLaw, filters.areasOfLaw)) return false;
        }
      }

      // Areas of Interest
      if (filters.areasOfInterest.length > 0) {
        if (filters.areasOfInterestToggle === "all") {
          // all
          if (!containsAll(item.areas_of_interest, filters.areasOfInterest))
            return false;
        } else {
          // any
          if (!containsAny(item.areas_of_interest, filters.areasOfInterest))
            return false;
        }
      }

      return true;
    });
    setData({ ...cachedData, members: filteredMemberData });
  }

  function reset() {
    setFilters({ ...defaultFilterState });
  }

  const columns = [
    {
      field: "imageUri",
      headerName: "Portrait",
      maxWidth: 80,
      width: 80,
      resizable: false,
      renderCell: (params) => {
        if (!params.value) return;
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Avatar
              alt={params.row?.firstName + " " + params.row?.lastName}
              src={params.value}
            />
          </Box>
        );
      },
    },
    {
      field: "firstName",
      headerName: "First",
      renderCell: (params) => {
        if (!params.value) return;

        return (
          <a href={`/tech-lawyer?id=${params.row.recordNumber}`}>
            {params.value}
          </a>
        );
      },
    },
    {
      field: "lastName",
      headerName: "Last",
    },
    {
      field: "emails",
      headerName: "Email",
      valueGetter: (val) => val.join(", "),
      flex: 2,
    },
    {
      field: "addresses",
      headerName: "Country",
      valueGetter: (val) => {
        if (!val) return;

        return val.map((a) => (a?.country ? a.country : null)).join(", ");
      },
      // valueFormatter: (val) => val.map((a) => a.country).join(", "),
      // sortComparator: (a, b) => {
      //   a[0].country
      // }
    },
    {
      field: "Languages",
      headerName: "Languages",
      valueGetter: (val) => val.join(", "),
      flex: 2,
    },
    {
      field: "AreasofLaw",
      headerName: "Areas of Law",
      // maxWidth: 500,
      flex: 2,
      valueGetter: (val) => val.join(", "),
      // renderCell: (params) => (
      //   <Box>
      //     <Typography sx={{ fontSize: 12 }}>
      //       {params.value.join(", ")}
      //     </Typography>
      //   </Box>
      // ),
    },
    {
      field: "areas_of_interest",
      headerName: "Areas of Interest",
      // maxWidth: 500,
      flex: 2,
      valueGetter: (val) => val.join(", "),
      // renderCell: (params) => (
      //   <Box>
      //     <Typography sx={{ fontSize: 12 }}>
      //       {params.value.join(", ")}
      //     </Typography>
      //   </Box>
      // ),
    },
  ];

  return (
    <Grid2 container sx={{ maxWidth: "100%", fonSize: "1rem" }}>
      {loading && <CircularProgress />}
      {error && (
        <Box sx={{ p: 1, border: 1, borderColor: "error.main" }}>
          {error.details}
        </Box>
      )}
      {isTableView && selectOptions && (
        <Filters
          filters={filters}
          handleSubmit={handleFilterSubmit}
          handleChange={handleFilterChange}
          handleToggle={handleAreaToggleChange}
          handleReset={reset}
          selectOptions={selectOptions}
        />
      )}

      {isTableView && data?.members.length > 0 && (
        <Box
          sx={{
            height: 700,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <DataGrid
            pagination
            autoPageSize
            getRowId={(r) => r.recordNumber}
            columns={columns}
            rows={data.members}
            // autosizeOnMount
            autosizeOptions={{
              expand: true,
              includeOutliers: true,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  // Hide columns status and traderName, the other columns will remain visible
                  Country: false,
                },
              },
            }}
          />
        </Box>
      )}
      {isTableView && !data?.members.length && (
        <Box
          sx={{
            p: 1,
            border: "1px solid #999",
            borderRadius: 1,
            textAlign: "center",
            width: "100%",
          }}
        >
          No Results
        </Box>
      )}
      {!isTableView && data?.members.length > 0 && (
        <Lawyer data={data.members.find((m) => m.recordNumber === record)} />
      )}
    </Grid2>
  );
}

let appRoot = document.getElementById("find-a-tech-lawyer-root");

if (appRoot) {
  appRoot = createRoot(appRoot);
  appRoot.render(<App />);
}
